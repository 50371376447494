import React, { useEffect, FC } from 'react';
import classnames from 'classnames';
import { StickyTool } from '@tencent/coral-design';
import { Breadcrumb, TdBreadcrumbItemProps, Loading } from 'tdesign-react';
import { ProductHeader } from 'components/header';
import { Modal } from 'components/modal';
import { UserLoginModal, getQQConnectURL } from 'components/user';
import 'components/css/pc/reset.less';
import 'tdesign-react/es/style/index.css';
import './style.less';
import useLogin from './hook/useLogin';
import useEffectOnce from 'components/hooks/useEffectOnce';
import { getProductId } from 'components/util';
import { PvCollector } from 'components/report';
const cls = 'base-page-pc';
interface BasePagePcProps {
  /** 面包屑配置 */
  breadcrumbOtion?: TdBreadcrumbItemProps[];
  /** 类名 */
  className?: string;
  /** TODO 旧的获取account方法 之后在覆盖 */
  getAccountInfo?: (any) => any;
  /** 页面状态 */
  status?: PAGE_STATUS,
  /** 是否展示登录弹窗 */
  isShowLogin?: boolean;
  /** 是否展示顶部官网提示 */
  hasRelated?: boolean;
  onLoginClose?: () => void
  /** 常见问题 */
  getFaqs?: boolean | (() => void)
  /** 获取团队博客 */
  getTeamblog?: boolean | (() => void)
  getUserInfo?: boolean | (() => void)
  getProductInfo?: boolean | ((val:any) => void)
  /** 是否有博客列表 */
  hasBlogList?: boolean
  hasFaqList?: boolean
}
/** 默认的一些页面状态，对应一些页面UI */
export enum PAGE_STATUS {
  /** 默认 */
  DEFAULT = 'DEFAULT',
  /** 加载态 */
  LOADING = 'LOADING',
  /** 空状态 */
  EMPTY = 'EMPTY',
  /** 错误页面 */
  ERROR = 'ERROR',
}
const BasePagePc: FC<BasePagePcProps> = (props) => {
  const { isShowLogin, showLogin, hideLogin } = useLogin();
  const changeShowLogin = nextState => () => {
    nextState ? showLogin() : hideLogin();
  };
  useEffectOnce(() => {
    /** pv upload */
    new PvCollector({ productId: getProductId(), type: 1 }).report();
  });
  // 响应嵌套组件对登录弹窗的控制
  useEffect(() => {
    props.isShowLogin ? showLogin() : hideLogin();
  }, [hideLogin, props.isShowLogin, showLogin]);
  /** 处理关闭登录弹窗回调  */
  const handleLoginClose = () => {
    changeShowLogin(false)();
    props.onLoginClose && props.onLoginClose();
  };
  return (
    <div className={classnames(cls, props.className)}>
      {/* 顶部导航 以及用户、产品信息 */}
      <ProductHeader
        onShowLogin={changeShowLogin(true)}
        getAccountInfo={props.getAccountInfo}
        getFaqs={props.getFaqs}
        getTeamblog={props.getTeamblog}
        getUserInfo={props.getUserInfo}
        getProductInfo={props.getProductInfo}
        hasBlogList={props.hasBlogList}
        hasFaqList={props.hasFaqList}
        hasRelated={props.hasRelated}
      />
      <div className={classnames(`${cls}__container`, {
        [`${cls}__container--bread`]: !!props.breadcrumbOtion,
      })}>
        {/* 面包屑导航 */}
        {props.breadcrumbOtion && <Breadcrumb options={props.breadcrumbOtion} maxItemWidth="1000px" />}
        {/* 覆盖整个页面的通用加载态 */}
        {props.status === PAGE_STATUS.LOADING && (<Loading />)}
        {/* 非加载时展示正常页面 */}
        {props.status !== PAGE_STATUS.LOADING && props.children}
      </div>
      <StickyTool />
      {/* 登录弹窗 */}
      {isShowLogin && (
        <Modal onClose={handleLoginClose}>
          <UserLoginModal
            qqConnectURL={getQQConnectURL()}
            onError={(error) => {
              throw error;
            }}
            onSuccess={() => location.reload()}
          />
        </Modal>
      )
      }
    </div>
  );
};
BasePagePc.defaultProps = {
  getAccountInfo: () => { },
  getUserInfo: () => { },
  getProductInfo: () => { },
  hasRelated: true,
  hasFaqList: false,
  hasBlogList: false,
  getFaqs: true,
  getTeamblog: true,
};
export default BasePagePc;
