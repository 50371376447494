import $ from '@tencent/jquery.ajax';
import { getUrlParam } from 'components/href-helper';
import { importVercodeScript, verificationCode } from 'components/util';
import { checkResponseRedirect } from 'components/util/redirect';

/** infrastructure ajax */
export function request(opts) {
  /**
     * ProgressEvent 补丁
     * @param xhr
     * @param cb
     */
  const PatchProgressEvent = (xhr, cb) => {
    const handle = function (progressEvent) {
      // 过滤掉不可用的进度
      if (!progressEvent.lengthComputable) {
        return;
      }

      return cb(progressEvent);
    };

    // 上传的进度
    if (xhr.upload) {
      xhr.upload.addEventListener('progress', handle, false);
    }

    // 下载的进度
    xhr.addEventListener('progress', handle, false);
    return xhr;
  };

  /**
     * oa.m.tencent.com 下会追加一级路径，需要特殊处理
     * @param url
     */
  const PatchOAMDomain = (url) => {
    const OAMHost = 'oa.m.tencent.com';

    if (window.location.host !== OAMHost) {
      return url;
    }

    const firstPath = window.location.pathname.split('/')[1];

    if (!firstPath) {
      return url;
    }

    return `https://${OAMHost}/${firstPath}${url}`;
  };

  Object.assign(opts, {
    xhr: () => {
      // 获取原始的 xhr 对象
      let originXHR = $.ajaxSettings.xhr();

      // 如果有进度监听的函数，补上对 progress 事件的监听
      if (opts.onProgress) {
        originXHR = PatchProgressEvent(originXHR, opts.onProgress);
      }

      return originXHR;
    },
    url: PatchOAMDomain(opts.url),
  });

  return new Promise((resolve, reject) => {
    const ajax = $.ajax(opts);

    ajax.done((resp) => {
      // 人机校验逻辑需要递归 单独拿出来写
      if (resp.status === '5001' && process.env.TARO_ENV !== 'weapp') {
        if (!window.dynamicImportVercodeScript) {
          window.dynamicImportVercodeScript = importVercodeScript();
        }
        return window.dynamicImportVercodeScript
          .then(() => verificationCode('captcha_setting_app')
            .then(({ randstr, ticket }) => {
              resolve(request({
                ...opts,
                data: JSON.stringify({
                  ...JSON.parse(opts.data),
                  captcha_randstr: randstr,
                  captcha_ticket: ticket,
                }),
              }));
            }));
      }

      if (resp.status !== 0 && !opts.isWjApi) {
        const err = new Error(resp.message);
        err.response = resp;
        checkResponseRedirect(resp.status, resp.message);
        reject(err);
        return;
      }

      resolve(resp);
    });

    ajax.fail((jqXHR, textStatus, errorThrown) => {
      // 当用户主动 abort 的使用，也会触发 fail，但此时没有 errorThrown
      // 这种 abort 的行为，不触发 resolve 和 reject，保持 pending 状态即可
      // https://github.com/jquery/jquery/blob/cf84696fd1d7fe314a11492606529b5a658ee9e3/src/ajax/xhr.js#L96
      if (!errorThrown) {
        return;
      }

      reject(errorThrown);
    });
  });
}

/**
 * GET 数据
 * @param {object} opts params
 */
export function getJson(opts) {
  const defaultOpts = {
    type: 'get',
    dataType: 'json',
    cache: false,
  };
  const communityId = getUrlParam('community_id');
  if (communityId) {
    if (opts.data) {
      opts.data.community_id = communityId;
    } else {
      opts.data = {
        community_id: communityId,
      };
    }
  }
  return request(Object.assign({}, defaultOpts, opts));
}

/**
 * POST 数据
 * @param opts
 */
export function postJSON(opts) {
  const communityId = getUrlParam('community_id');
  if (communityId) {
    if (opts.data) {
      opts.data.community_id = communityId;
    } else {
      opts.data = {
        community_id: communityId,
      };
    }
  }
  opts.data = JSON.stringify(opts.data);

  const defaultOpts = {
    type: 'POST',
    dataType: 'json',
    processData: false,
    contentType: 'application/json; charset=UTF-8',
  };

  return request(Object.assign({}, defaultOpts, opts));
}

/**
 * delete 数据
 * @param {object} opts 参数对象
 */
export function deleteJson(opts) {
  const defaultOpts = {
    type: 'delete',
    dataType: 'json',
  };

  return request(Object.assign({}, defaultOpts, opts));
}

/**
 * POST 表单类型
 * @param opts
 */
export function postFormData(opts) {
  const defaultOpts = {
    type: 'post',
    processData: false,
    contentType: false,
  };

  return request(Object.assign({}, defaultOpts, opts))
    .catch(res => res);
}
