import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { REPORT_REASON } from './reason';

import './modal-report.less';

export class ModalReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inpIndex: 0, // 选择的input的下标
        };
    }

    onSubmit(event) {

        const { props, state } = this;

        event.preventDefault();

        const { value } = event.target.querySelectorAll('input')[state.inpIndex];

        props.onReport(value);

    }

    render() {

        const { state, props } = this;

        return (
            <div className="modal-report">
                <div className="report-container">

                    <form onSubmit={event => this.onSubmit(event)}>

                        <div className="report-title">举报原因</div>

                        <div className="options">
                            {REPORT_REASON.map((item, i) => (
                                <div key={item.key} className="item" onClick={() => this.setState({ inpIndex: i })}>
                                    <input type="radio" name="report" id={`modal-report-${item.key}`} value={item.key} />
                                    <label htmlFor={`modal-report-${item.key}`}>
                                        <i /><span>{item.value}</span>
                                    </label>
                                </div>
                            ))}
                        </div>

                        <div className="btns-wrap action-bar">
                            <button className="btn btn-main" type="submit">举报</button>
                        </div>

                    </form>

                </div>
            </div>
        );

    }
}

ModalReport.propTypes = {
    onReport: PropTypes.func,
};

ModalReport.defaultProps = {
    onReport() { console.log('onReport 未绑定') }
};

