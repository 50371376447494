/**
 * 事件埋点
 * @constructor
 * @param {t、ec}   固定参数
 * @param {ea}      自定义值
 * @param {ev}      自定义值
 * @param {el}      自定义值
 * @param {url}     地址
 */
import { get_api_collect as getApiCollect } from 'components/api';
import { getProductId } from 'components/util';
import { init as horizonInit } from './horizon';
import aegis from './aegis';
class Collect {
  constructor({ t = 'event', ea = '', ev = '', ec = 'button', el = '', url = location.href, productId = '' }) {
    this.horizonTrack = this.horizonTrack.bind(this);
    this.ea = ea;
    this.ev = ev;
    this.ec = ec;
    this.el = el;
    this.productId = productId || getProductId();
    this.params = [
      `t=${t}`,
      `ea=${ea}`,
      `ev=${ev}`,
      `ec=${ec}`,
      `el=${el}`,
      'clt=0',
      'pdt=0',
      `z=${Math.random().toString()}`,
      `dl=${encodeURIComponent(url)}`,
      'data-load-start=0',
      'data-load-end=0',
      'data-parse-end=0',
      'data-render-end=0',
      'user_info_load=0',
      'v=1',
      'account=tucao',
      'ds=web',
      `fid=${this.productId}`,
      `sr=${screen ? `${screen.width}x${screen.height}` : '-'}`,
      `vp=${`${window.innerWidth}x${window.innerHeight}`}`,
      `ps=${window.performance && window.performance.now ? 'true' : 'false'}`,
    ];
  }

  report() {
    const { params } = this;

    const img = new Image();
    img.style.position = 'fixed';
    img.style.visibility = 'hidden';
    img.src = `${getApiCollect()}?${params.join('&')}`;
    // horizon数据上报
    if (window._horizon) {
      this.horizonTrack();
    } else {
      horizonInit(this.horizonTrack);
    }
    // TAM 事件上报
    aegis.report({
      ea: this.ea,
      ev: this.ev,
      ec: this.ec,
      el: this.el,
    });
  }

  horizonTrack() {
    if (window._horizon.send && window._horizon.setObject) {
      window._horizon.setObject(this.productId);
      window._horizon.send(this.ec, this.ea, this.el, this.ev);
    }
  }
}

export { Collect };
